@media only screen and (max-width: 767px) {
  .header{
    .header-top{
      .header-top-menu{
        margin-top:20px;
        ul{
          li{
            a{
              font-size: 12px;
            }
          }
        }
      }
    }
    .header-bottom{
      .navbar {
        padding: 0px;
        text-align: center;
        display: block;
      .navbar-toggler{
        background-color: #000;
        padding: 12px 20px;
        i{
          color: #fff;
        }
      }
     .navbar-collapse{
      .navbar-nav {
        .nav-item {
          .nav-link{
            text-align: left;
          }
        }
      }
     }
    }
  }
}
  .dealCard {
    padding: 20px 0px 40px;
    .dealCardBox {
      .dealCardBoxDetail {
        width: 100%;
        h2{
          font-size: 22px;
        }
      }
      .dealCardBoxImage{
        &.dealCardBox-orangeImage{
          display: none;
        }
        &.dealCardBox-whiteImage{
          display: none;
        }
      } 
    }
  }
  .footer-link {
    margin-top: 20px;
  }
  .footer-rated-products {
    margin-top: 20px;
  }
  .cart-section{
    .cart-section-head{
      h2{
        font-size: 22px;
      }
    }
  } 
  .product-list-head {
    ul {
      display: block;
      li{
        display: inline-block;
        text-align: center;
        margin: 0 5px;
        a {
          font-size: 12px;
        }
      }
    }  
  } 
  .newsletter-subs {
    margin-top: 20px;
  }

  .plant-group {
    margin-bottom: 30px;
    .plant-type-option {
      margin: 0px auto !important;
    }
  }

 
  }

@media screen and (max-width: 992px) and (min-width: 768px){
  .dealCard {
    .dealCardBox {
      .dealCardBoxDetail {
        width:80%;
        h2{
          font-size: 22px;
        }
        p{
          font-size: 15px;
        }
      }
    }
  }
  .footer-about {
    margin-bottom: 40px;
  }
  .product-list-head{
    ul{
      li{
        a{
          font-size: 16px;
        }
      }
    }
  } 
}

@media (max-width: 1200px) and (min-width: 992px) {
  .product-detail {
    .product-detail-right {
      .product-detail-content {
        .product-detail-mid {
          .plant-group {
            .plant-type-option {
              .form-control {
                font-size: 14px;
              }
              &::after {
                font-size: 20px !important;
                top: -3px !important;
              }
            }
          }
        }
      }
    }
  }
}