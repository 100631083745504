@import "../Styles/variables.scss";

//profile css
.cart-section {
  .cart-section-head {
    margin-bottom: 30px;
    h2 {
      margin: 0px;
      color: #000000;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 700;
    }
    p {
      margin-bottom: 0px;
      color: #000000;
      font-size: 18px;
    }
  }
  .highlight {
    color: lawngreen;
    pointer-events: none;
  }
  //checkout-body
  .profile-body {
    .profile-form {
      .form-group {
        .form-control {
          border-color: #d1d1d1;
          border-radius: 0px;
          font-size: 12px;
          //text-transform: uppercase;
          color: #000;
          height: 36px;
          &:hover,
          &:focus {
            border-color: #d1d1d1;
          }
        }
      }
      .checkout-btn {
        text-align: center;
        margin: 30px 0px 20px;
        button {
          border: 1px solid #e25e52;
          color: #e25e52;
          background-color: transparent;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 6px 20px;
          min-width: 250px;
          &:hover {
            background-color: #e25e52;
            color: #fff;
          }
        }
      }
    }
  }
}

.upload-btn {
  button {
    border: 1px solid $color-main;
    color: $color-main;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    &:hover,
    &:focus {
      background-color: $color-main;
      color: #fff;
    }
  }
}

//profile
.profile {
  //profile-picture
  .profile-picture {
    text-align: center;
    border: 1px solid #d1d1d1;
    padding: 15px 15px 40px;
    .profile-picture-head {
      h3 {
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;
        text-transform: uppercase;
      }
    }
    .upload-btn {
      button {
        border: 1px solid #e25e52;
        color: #e25e52;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        &:hover,
        &:focus {
          background-color: #e25e52;
          color: #fff;
        }
      }
    }
  }
}

.product-preview-image {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

// / Hide scrollbar for Chrome, Safari and Opera /
// .example::-webkit-scrollbar {
//   display: none;
// }

// / Hide scrollbar for IE, Edge and Firefox /
// .example {
//   -ms-overflow-style: none;  / IE and Edge /
//   scrollbar-width: none;  / Firefox /
// }

.ReactCrop {
  max-height: 250px;
  max-width: 250px !important;
  // display: flex;
  justify-content: center;
  overflow: auto !important;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .profile {
    .profile-picture {
      margin-bottom: 30px;
    }
  }
  .cart-section {
    margin-top: 30px;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .profile {
    .profile-picture {
      margin-bottom: 30px;
    }
  }
}

//profile slider style ----------------
.user-profile-img {
  margin-bottom: 20px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    list-style: none;
    margin: 0;
    padding: 10px;
    border: 1px solid #d1d1d1;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #d95557;
    }
    li {
      padding-right: 10px;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .profile-img {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
  }
}

.profile-custom-set {
  padding: 10px;
  border: 1px solid #d1d1d1;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
}
